import { queryOptions } from '@tanstack/react-query'
import { type TransactionResult } from '../../models/transactionResult'
import {
  DewateringAbstractionStatus,
  FavouriteSiteType,
  UserFavouriteSites,
  DowntimeSiteSummary,
  type Site,
  SiteBoreUtilisation,
  DewateringBoreOverviewReport,
  BoreDetailsType,
  BoreDowntimeReason,
  BoreDowntimeReasonResponse,
  BoreOverviewGroupType,
  BoreDowntimeReportByPit,
  DowntimeSiteOverview,
} from './api.types'
import config from '../../config.json'
import { apiClient } from '../../services/client'
import { GetUserIdAsync } from '../../utils/initializeAtomUser'
import { ActivityLogApiType } from '../../components/activity-log/activity-log.types'

const getBaseURL = () => {
  return config.backend || window.location.origin
}

export const getSitesListQuery = () =>
  queryOptions<TransactionResult<Site[]>>({
    queryKey: ['sites', 'list'],
    queryFn: async () => {
      const url = new URL('/api/Dewatering/GetSites', getBaseURL())

      const response = await apiClient.get(url.pathname, {})

      return response.data
    },
  })

export const getSiteByCodeQuery = (siteCode: Site['code']) =>
  queryOptions<TransactionResult<Site>>({
    queryKey: ['sites', 'item', siteCode],
    queryFn: async () => {
      const url = new URL('/api/Dewatering/GetSites', getBaseURL())

      url.searchParams.set('siteCode', siteCode)

      const response = await apiClient.get(url.pathname, {
        params: {
          siteCode: siteCode,
        },
      })

      return response.data
    },
  })

export const getSiteByCodeDewateringAbstractionStatusQuery = (
  siteCode: Site['code'],
  start: string | null = null,
  end: string | null = null,
) =>
  queryOptions<TransactionResult<DewateringAbstractionStatus>>({
    queryKey: ['dewatering', 'abstraction', siteCode, start, end],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/${siteCode}/GetSiteAbstraction`, getBaseURL())

      if (start !== null) url.searchParams.set('startTime', start)
      if (end !== null) url.searchParams.set('endTime', end)

      const response = await apiClient.get(url.pathname, {
        params: {
          startTime: start,
          endTime: end,
        },
      })

      return response.data
    },
  })

export const getDewateringBoreOverviewReportQuery = (
  siteCode: Site['code'],
  start: string | null = null,
  end: string | null = null,
) =>
  queryOptions<TransactionResult<DewateringBoreOverviewReport>>({
    queryKey: ['dewatering', 'bore-overview-report', siteCode, start, end],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/${siteCode}/BoreOverviewReport`, getBaseURL())

      if (start !== null) url.searchParams.set('startTime', start)
      if (end !== null) url.searchParams.set('endTime', end)

      const response = await apiClient.get(url.pathname, {
        params: {
          startTime: start,
          endTime: end,
        },
      })

      return response.data
    },
  })

export const getUserFavouriteSites = (userId: string | null = null) =>
  queryOptions<TransactionResult<UserFavouriteSites[]>>({
    queryKey: ['favouriteSites'],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/GetUserFavouriteSites`, getBaseURL())

      if (userId === '' || userId === null) userId = await GetUserIdAsync()

      url.searchParams.set('userId', userId)

      const response = await apiClient.get(url.pathname, {
        params: {
          userId: userId,
        },
      })

      return response.data
    },
  })

export const setPinnedSiteEndpoint = (postPinnedSiteData: FavouriteSiteType) =>
  apiClient.post<TransactionResult<FavouriteSiteType>>('/api/Dewatering/AddUserFavouriteSite', postPinnedSiteData)

export const deletePinnedSiteEndpoint = (deletePinnedSiteData: FavouriteSiteType) =>
  apiClient.delete<TransactionResult<FavouriteSiteType>>('/api/Dewatering/DeleteUserFavouriteSite', {
    data: deletePinnedSiteData,
  })

export const getDowntimeEndpoint = (
  siteCode: Site['code'],
  start: string | null = null,
  end: string | null = null,
  activeBoresOnly: string = 'true',
) =>
  queryOptions<TransactionResult<DowntimeSiteOverview[]>>({
    queryKey: ['dewatering', 'downtime', siteCode, start, end],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/GetBoreDowntime`, getBaseURL())

      if (siteCode !== null) url.searchParams.set('siteCode', siteCode)
      if (start !== null) url.searchParams.set('startTime', start)
      if (end !== null) url.searchParams.set('endTime', end)
      url.searchParams.set('activeBoresOnly', activeBoresOnly)

      const response = await apiClient.get(url.pathname, {
        params: {
          siteCode: siteCode,
          startTime: start,
          endTime: end,
          activeBoresOnly: activeBoresOnly,
        },
      })

      return response.data
    },
  })

export const getDowntimeReportByPitEndpoint = (
  siteCode: Site['code'],
  start: string | null = null,
  end: string | null = null,
  plannedBoresOnly: string = 'false',
) =>
  queryOptions<TransactionResult<BoreDowntimeReportByPit[]>>({
    queryKey: ['dewatering', 'downtime-pit-report', siteCode, start, end],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/${siteCode}/BoreDowntimeReportByPit`, getBaseURL())

      if (siteCode !== null) url.searchParams.set('siteCode', siteCode)
      if (start !== null) url.searchParams.set('startTime', start)
      if (end !== null) url.searchParams.set('endTime', end)
      url.searchParams.set('plannedBoresOnly', plannedBoresOnly)

      const response = await apiClient.get(url.pathname, {
        params: {
          siteCode: siteCode,
          startTime: start,
          endTime: end,
          plannedBoresOnly: plannedBoresOnly,
        },
      })

      return response.data
    },
  })

export const getDowntimeEndpointSummary = (
  siteCode: Site['code'],
  start: string | null = null,
  end: string | null = null,
  activeBoresOnly: string = 'true',
) =>
  queryOptions<TransactionResult<DowntimeSiteSummary>>({
    queryKey: ['dewatering', 'downtimeSummary', siteCode, start, end, activeBoresOnly],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/GetDowntimeSummary`, getBaseURL())

      if (siteCode !== null) url.searchParams.set('siteCode', siteCode)
      if (start !== null) url.searchParams.set('startTime', start)
      if (end !== null) url.searchParams.set('endTime', end)
      url.searchParams.set('activeBoresOnly', activeBoresOnly)

      const response = await apiClient.get(url.pathname, {
        params: {
          siteCode: siteCode,
          startTime: start,
          endTime: end,
          activeBoresOnly: activeBoresOnly,
        },
      })

      return response.data
    },
  })

export const GetSiteUtilisation = (siteCode: Site['code'], start: string | null = null, end: string | null = null) =>
  queryOptions<TransactionResult<SiteBoreUtilisation>>({
    queryKey: ['dewatering', 'site-utilisation', siteCode, start, end],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/GetSiteUtilisation`, getBaseURL())

      if (siteCode !== null) url.searchParams.set('siteCode', siteCode)
      if (start !== null) {
        url.searchParams.set('startTime', start)
        if (end === null) {
          url.searchParams.set('endTime', start)
        }
      }
      if (end !== null) url.searchParams.set('endTime', end)

      const response = await apiClient.get(url.pathname, {
        params: {
          siteCode: siteCode,
          startTime: start,
          endTime: end,
        },
      })

      return response.data
    },
  })

export const getDewateringBoreOverviewQuery = (
  siteCode: Site['code'],
  start: string | null = null,
  end: string | null = null,
) =>
  queryOptions<TransactionResult<BoreOverviewGroupType[]>>({
    queryKey: ['dewatering', 'bore-overview', siteCode, start, end],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/${siteCode}/GetBoreOverview`, getBaseURL())

      if (start !== null) url.searchParams.set('startTime', start)
      if (end !== null) url.searchParams.set('endTime', end)

      const response = await apiClient.get(url.pathname, {
        params: {
          startTime: start,
          endTime: end,
        },
      })
      return response.data
    },
  })

export const getDewateringBoreDetailsQuery = (siteCode: Site['code'], boreName: string | null = null) =>
  queryOptions<TransactionResult<BoreDetailsType>>({
    queryKey: ['dewatering', 'bore-detail', siteCode, boreName],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/${siteCode}/GetBoreDetails/${boreName}`, getBaseURL())

      const response = await apiClient.get(url.pathname)

      return response.data
    },
  })

export const getDewateringAllBoreDowntimeReasonQuery = () =>
  queryOptions<TransactionResult<BoreDowntimeReason[]>>({
    queryKey: ['dewatering', 'bore-downtime-reason'],
    queryFn: async () => {
      const url = new URL('/api/Dewatering/GetAllBoreDowntimeReasons', getBaseURL())

      const response = await apiClient.get(url.pathname)

      return response.data
    },
  })

export const getBoreActivityLog = (siteCode: Site['code'], boreName: string | null = null) =>
  queryOptions<TransactionResult<ActivityLogApiType[]>>({
    queryKey: ['dewatering', 'bore-activity-log-details', siteCode, boreName],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/${siteCode}/GetBoreActivityLog/${boreName}`, getBaseURL())

      const response = await apiClient.get(url.pathname)

      return response.data
    },
  })

export const getActivityLogByDowntimeId = (parentObjectId: string | null = null) =>
  queryOptions<TransactionResult<ActivityLogApiType[]>>({
    queryKey: ['dewatering', 'bore-activity-log-downtime', parentObjectId],
    queryFn: async () => {
      const url = new URL(`/api/Dewatering/GetActivityLogByParentId/${parentObjectId}`, getBaseURL())

      const response = await apiClient.get(url.pathname)

      return response.data
    },
  })

export const updateDewateringDowntimeReason = async (
  downtimeId: string,
  downtimeReason: string,
  email: string,
  comment: string,
): Promise<TransactionResult<BoreDowntimeReasonResponse>> => {
  const url = new URL('/api/Dewatering/UpdateDowntimeReason', getBaseURL())

  const response = await apiClient.post(url.pathname, {
    downtimeId,
    downtimeReason,
    email,
    comment,
  })

  return response.data as TransactionResult<BoreDowntimeReasonResponse>
}
